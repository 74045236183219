import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { AppConstants } from '../interfaces/AppConstants';
import { BPListResponse, BPResponse, PaginatorBPResponse, QuantityBPResponse } from '../interfaces/ResponseModel';

@Injectable({
  providedIn: 'root',
})
export class BusinessPartnerService {
  constructor(private http: HttpClient) { }

  //METODO PARA OBTENER LOS BP POR EL CLICODE
  public getBPData(CliCode: string): Observable<BPResponse> {
    return this.http.get<BPResponse>(
      `${environment.apiUrl}api/BusinessPartner/GetBPData?CliCode=${CliCode}`
    );
  }

  //METODO PARA OBTENER N LISTADO DE BP
  public getBP(CliCode: string): Observable<BPListResponse> {
    return this.http.get<BPListResponse>(`${environment.apiUrl}api/BusinessPartner/GetBP?AddID=${CliCode}`);
  }

  //METODO PARA OBTENER LOS BP PAGINADOS
  public getBPPaginator(skip: number, take: number, filtro: string): Observable<PaginatorBPResponse> {
    return this.http.get<PaginatorBPResponse>(`${environment.apiUrl}api/BusinessPartner/GetBPPaginator?skip=${skip}&take=${take}&filtro=${filtro}`);
  }

  //METODO PARA OBTENER LA CANTIDAD DE BPs
  public getQuantityBp(filtro: string): Observable<QuantityBPResponse> {
    return this.http.get<QuantityBPResponse>(`${environment.apiUrl}api/BusinessPartner/GetQuantityBP?filtro=${filtro}`);
  }
}
