<mat-card class="container mt-5  p-0" fxFlex="95" fxFlex-lt.sm="80" fxFlex.sm="60" fxFlex.gt-sm="30">
    <div class="header blue-background-color">
        <div class="pt-3">
            <mat-icon>error</mat-icon>
            <h1 class="error">404</h1>
        </div>
    </div>
    <div class="content">
        <p>Opps, parece que esta página no existe..</p>
        <p>Click en el botón para ir a inicio</p>
        <button mat-raised-button color="primary" class="mat-elevation-z8" type="button" (click)="returnPage()">
            <mat-icon>home</mat-icon>
        </button>
    </div>
</mat-card>
