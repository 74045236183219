import {AfterViewInit, ChangeDetectorRef, Component, OnInit, ViewChild} from '@angular/core';
import { PermsByUserViewModel } from '../core/interfaces/ViewModel';
import { AuthenticationService } from '../core/services/authentication.service';
import { GlobalFunctionsService } from '../core/services/global-functions.service';
import { InternalRequestService } from '../core/services/internal-request.service';
import { SectionService } from '../core/services/section.service';
import { SidenavMenu } from '../shared/sidenav-menu/sidenav-menu-model';

@Component({
  selector: 'app-pages',
  templateUrl: './pages.component.html',
  styleUrls: ['./pages.component.scss'],
})
export class PagesComponent implements OnInit, AfterViewInit{
  //VARIABLE QUE ALMACENA LA MEDIA QUERY
  @ViewChild('snav') snav: any;

  public sidenavMenuItems: any[] = [];
  currentUser: any; // datos de sesion
  isExpanded = false;
  public opened = true;
  // lista de permisos
  permList: PermsByUserViewModel[] = [];

  constructor(
    private internalRequestService: InternalRequestService,
    private authenticationService: AuthenticationService,
    public sectionService: SectionService,
    private gblSrv: GlobalFunctionsService,
    private cd: ChangeDetectorRef
  ) {
    this.authenticationService.currentUser.subscribe((next) => {
      this.currentUser = next;
    });

    this.authenticationService.currentPerms.subscribe((next) => {
      this.permList = next;
      if (this.sidenavMenuItems && this.sidenavMenuItems.length > 0) {
        this.sidenavMenuItems = this.checkMenuPerms(this.sidenavMenuItems);
      }
    });
  }

  ngOnInit(): void {
    this.onLoad();
  }
  ngAfterViewInit(): void {
    this.cd.detectChanges();
  }
  onLoad(): void {
    this.getMenu();
  }

  // OBTENEMOS EL MENU
  public getMenu(): any {
    this.internalRequestService.getMenu().subscribe({
      next: (data: SidenavMenu[]) => {
        this.sidenavMenuItems.length = 0;
        // this.sidenavMenuItems = this.checkMenuPerms(data);
        this.sidenavMenuItems = data;
        this.sidenavMenuItems = this.checkMenuPerms(this.sidenavMenuItems);
      },
      error: (err: string) => {
        console.log('error');
        console.log(err);
      },
    });
  }

  checkMenuPerms(menu: SidenavMenu[]) {
    menu.forEach((menuElement) => {
      menuElement.havePerm =
        menuElement.id === 1 || menuElement.id === 8
          ? true
          : this.gblSrv.checkPerm(menuElement.perm);
      if (menuElement.submenu.length > 0) {
        menuElement.submenu.forEach((submenuElement) => {
          submenuElement.havePerm = this.gblSrv.checkPerm(submenuElement.perm);
        });
      }
    });
    return menu;
  }
}
