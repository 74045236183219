import {Component} from '@angular/core';
import {Settings, AppSettings} from './app.settings';
import {environment} from "../environments/environment";
// import LogRocket from 'logrocket';
import * as LogRocket from 'logrocket';
import {AuthenticationService} from "./core/services/authentication.service";

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})

export class AppComponent {
  title = 'ClvsMisMdsmRbtUI';

  public settings: Settings;
  currentUser: any; // datos de sesion
  // firstEntry: boolean = false;

  constructor(
    public appSettings: AppSettings,
    private authenticationService: AuthenticationService
  ) {
    this.settings = this.appSettings.settings;
    if (environment.production) {
          LogRocket.init(environment.LogRocketId);
    }
  }


}
