import { Injectable } from '@angular/core';
import { MatSnackBar, MatSnackBarVerticalPosition } from '@angular/material/snack-bar';
import { BehaviorSubject } from 'rxjs';
import { SidenavMenu } from 'src/app/shared/sidenav-menu/sidenav-menu-model';
import { PermsByUserViewModel } from '../interfaces/ViewModel';
import { DataStorageService } from './data-storage.service';

@Injectable({
  providedIn: 'root',
})
export class GlobalFunctionsService {

  constructor(
    private dataStorageSvc: DataStorageService,
    private snackBar: MatSnackBar
  ) { }

  // Metodo para verificar la existencia de una permiso
  public checkPerm(permToCheck: string): boolean {
    let permList: PermsByUserViewModel[] = JSON.parse(
      this.dataStorageSvc.getStorage('perm')
    );
    if (permList && permList.length) {
      return permList.some((perm) => perm.NamePerm === permToCheck);
    } else {
      return false;
    }
  }

  // MUESTRA EL MENSAJE
  displayMessage(
    message: string,
    actionBtn: string,
    alertType: string,
    position: MatSnackBarVerticalPosition,
    duration: number
  ): void {
    this.snackBar.open(
      `${message}`,
      `${actionBtn}`,
      { panelClass: `${alertType}`, verticalPosition: `${position}`, duration: duration }
    );
  }
}
