import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor
} from '@angular/common/http';
import { catchError, Observable, throwError } from 'rxjs';
import { AppConstants } from 'src/app/core/interfaces/AppConstants';
import { AuthenticationService } from 'src/app/core/services/authentication.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { GlobalFunctionsService } from 'src/app/core/services/global-functions.service';
import { MessageType } from 'src/app/core/enum/message';
import { environment } from 'src/environments/environment';

@Injectable()
export class ErrorHandlerInterceptor implements HttpInterceptor {

  constructor(
    private authSvc: AuthenticationService,
    private gblSrv: GlobalFunctionsService,
    private router: Router
  ) { }

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {

    return next.handle(request).pipe(
      catchError((err) => {
        if (err.status === 401) {

          if (request.url.includes(environment.urlReportManager.split('/')[2])) {
            this.authSvc.sign_inReport
              (
                AppConstants.user,
                AppConstants.password
              ).subscribe({
                next: (res: any) => {
                  this.gblSrv.displayMessage(`Estimado usuario el token de los reportes expiró y fue actualizado!`, 'x', MessageType.information, 'top', 7000);
                },
                error: (err: string) => {
                  this.gblSrv.displayMessage(err, 'x', MessageType.information, 'top', 7000);
                }
              });

            this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
              this.router.navigate(['/Reports']);
            });
          }
        }

        const errorMsg = err.error.error_description || err.error.error || err.error.Message || err.statusText;
        return throwError(() => new Error(errorMsg));
      }));

  }
}
