<h2 mat-dialog-title>Buscar clientes</h2>
<hr class="mb-2">
<div class="mt-2">
  <mat-form-field appearance="outline" class="w-100">
    <mat-label>Buscar</mat-label>
    <input matInput  placeholder="Buscar"
      (keyup)="search($event)" />
  </mat-form-field>
</div>
<div class="overflow">
  <table mat-table [dataSource]="dataSource">

    <ng-container matColumnDef="Sel">
      <th mat-header-cell *matHeaderCellDef> Seleccionar </th>
      <td mat-cell *matCellDef="let element">
      <button mat-icon-button (click)="select(element)">
        <mat-icon>check</mat-icon>
      </button>
      </td>
    </ng-container>

    <ng-container matColumnDef="AddID">
      <th mat-header-cell *matHeaderCellDef> AddId </th>
      <td mat-cell *matCellDef="let element"> {{element.U_SFCli}} </td>
    </ng-container>

    <ng-container matColumnDef="CardCode">
      <th mat-header-cell *matHeaderCellDef> Código de cliente </th>
      <td mat-cell *matCellDef="let element"> {{element.CardCode}} </td>
    </ng-container>

    <ng-container matColumnDef="CardName">
      <th mat-header-cell *matHeaderCellDef> Nombre </th>
      <td mat-cell *matCellDef="let element"> {{element.CardName}} </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns;sticky:true"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
  </table>

</div>

<mat-paginator [length]="quantityBP"
               [pageSizeOptions]="[100,200]" showFirstLastButtons="true"
               aria-label="Select page" (page)="event($event)">
</mat-paginator>
<div class="mt-1 text-right">
  <button (click)="cancel()" color="basic" mat-raised-button>Cancelar</button>
</div>

