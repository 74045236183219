import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  RouterStateSnapshot,
  UrlTree,
} from '@angular/router';
import { Observable } from 'rxjs';
import { MessageType } from '../enum/message';
import { PermsByUserViewModel } from '../interfaces/ViewModel';
import { DataStorageService } from '../services/data-storage.service';
import { GlobalFunctionsService } from '../services/global-functions.service';

@Injectable({
  providedIn: 'root',
})
export class VerifyPermGuard implements CanActivate {
  /*VARIABLES*/
  enable: boolean = true;

  constructor(
    private dataStorageSvc: DataStorageService,
    private snackBar: MatSnackBar,
    private gblSrv: GlobalFunctionsService
  ) { }

  // GUARD PARA VERIFICAR LOS PERMISOS
  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    // let newRoute: string = route.url.map(x => x.path).join('-');

    //  const currentUser = this.authenticationService.currentUserValue;

    let listPerm: PermsByUserViewModel[] = JSON.parse(
      this.dataStorageSvc.getStorage('perm')
    );
    let requiredPerm: string = this.getRequiredPerm(state.url);
    // let havePerm: boolean = false;
    // havePerm = listPerm.some((perm) => perm.NamePerm === requiredPerm);
    let havePerm: boolean = this.gblSrv.checkPerm(requiredPerm);
    if (!havePerm) {
      this.gblSrv.displayMessage(`Acceso denegado, no posee permisos para esta opción!`, 'x', MessageType.warning, 'top', 2000);
    }
    return havePerm;
  }

  getRequiredPerm(route: string) {
    let requiredPerm: string = '';

    switch (route) {
      case '/ARInvoicesCreate':
        requiredPerm = 'S_CreateARInv'; //
        break;
      case '/Monitors': //
        requiredPerm = 'S_Monitors';
        break;
      case '/ClosingReports': //
        requiredPerm = 'S_ClosingReports';
        break;
      case '/InfoOpportunities': //
        requiredPerm = 'S_InfoOps';
        break;
      case '/CancelPayment': //
        requiredPerm = 'S_CancelPayment';
        break;
      case '/CurrentPayment': //
        requiredPerm = 'S_CurrentPayment';
        break;
      case '/Users':
        requiredPerm = 'S_UserAssigns'; //
        break;
      case '/Companys':
        requiredPerm = 'S_Companies'; //
        break;
      case '/SAPConnections': //
        requiredPerm = 'S_SAPConnections';
        break;
      case '/BACTerminals': //
        requiredPerm = 'S_BACTerminals';
        break;
      case '/TransactionLogs':
        requiredPerm = 'M_LogTran';
        break;
      case '/Reports': //
        requiredPerm = 'M_Report';
        break;
      case '/ConfigAutoSale': //
        requiredPerm = 'M_ConfigSale';
        break;
      case '/BacError': //
        requiredPerm = 'M_BACErrors';
        break;
    }
    return requiredPerm;
  }
}
