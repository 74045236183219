import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { AppConstants } from '../interfaces/AppConstants';

@Injectable({
  providedIn: 'root'
})
export class DataStorageService {


  constructor() { }

  public getToken(url: string) {

    let _url = url.split('/');

    if (environment.urlReportManager.includes(_url[2])) {
      return this.getStorage('UserReport');
    } else if (environment.apiUrl.includes(_url[2])) {
      return this.getStorage('currentUser');
    }

  }

  public setStorage(name: string, obj: any): void {
    localStorage.setItem(name, JSON.stringify(obj));
  }

  public getStorage(name: string): any {
    return localStorage.getItem(name);
  }

  public deleteStorage(name: string[]): void {
    name.forEach(element => {
      localStorage.removeItem(element);
    });

  }



}
