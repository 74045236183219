import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject, map, Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

import { BaseResponse, LoginResponse } from '../interfaces/ResponseModel';
import { StringModel, UserModel } from '../interfaces/UserModel';
import { PermsByUserViewModel } from '../interfaces/ViewModel';
import { DataStorageService } from './data-storage.service';

@Injectable({
  providedIn: 'root',
})
export class AuthenticationService {
  public currentUserSubject!: BehaviorSubject<any>;
  public currentUser!: Observable<any>;

  listStorage: string[] = ['UserReport', 'currentUser', 'perm'];

  //permisos
  public currentPermsSubject!: BehaviorSubject<PermsByUserViewModel[]>;
  public currentPerms!: Observable<PermsByUserViewModel[]>;

  constructor(
    private http: HttpClient,
    private storage: DataStorageService,
    private router: Router
  ) {
    this.currentUserSubject = new BehaviorSubject<any>(
      JSON.parse(localStorage.getItem('currentUser')!)
    );
    this.currentUser = this.currentUserSubject.asObservable();

    this.currentPermsSubject = new BehaviorSubject<any>(
      JSON.parse(localStorage.getItem('perm')!)
    );
    this.currentPerms = this.currentPermsSubject.asObservable();

  }

  public get currentUserValue(): any {
    return this.currentUserSubject.value;
  }

  public deleteCurrentUserValue(): any {
    this.currentUserSubject.next(null);
  }

  //METODO PARA INICIAR SESION
  public sign_in(
    username: string,
    password: string
  ): Observable<LoginResponse> {
    const body = new HttpParams()
      .set('grant_type', 'password')
      .set('UserName', username)
      .set('Password', password)
      .set("is_login", "true");

    const headers = new HttpHeaders({
      'Content-Type': 'application/x-www-form-urlencoded',
    });

    return this.http
      .post<LoginResponse>(`${environment.apiUrl}token`, body.toString(), {
        headers,
      })
      .pipe(
        map((user) => {
          if (user && user.access_token) {
            this.storage.setStorage('currentUser', user);
            this.currentUserSubject.next(user);
          }
          return user;
        })
      );
  }

  //METODO PARA ENVIAR EL EMAIL CON LOS PASOS PARA RECUPERAR EL PASSWORD
  public sendRecoverPswd(stringModel: StringModel) {
    return this.http.post<BaseResponse>(
      `${environment.apiUrl}api/Account/SendRecoverPswdEmail`,
      stringModel
    );
  }

  //METODO PARA CERRAR CESION
  public logout(): void {
    this.storage.deleteStorage(this.listStorage);
    this.currentUserSubject.next(null);
    this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
      this.router.navigate(['/sign-in']);
    });
  }

  //METODO PARA ACTUALIZAR LA CONTRASENA
  public updatePassword(userModel: UserModel) {
    return this.http.post<BaseResponse>(
      `${environment.apiUrl}api/Account/UpdatePassword`,
      userModel
    );
  }

  // VERIFICACION SI UN USUARIO ESTA LOGUEADO Y SI EL TOKEN NO HA EXPIRADO
  public isAuthenticated(): boolean {
    const TOKEN = JSON.parse(this.storage.getStorage('currentUser'));

    if (TOKEN) {
      var expires = new Date(TOKEN.ExpireTime);
      var today = new Date();
      if (today <= expires) {
        // posee token y el mismo aun no expira, es valido
        return true;
      }
    }
    return false;
  }

  // metodo para desloguearse al ingresar a verificar el usuario
  public logoutVerificationEmail(): void {
    this.storage.deleteStorage(this.listStorage);
    this.currentUserSubject.next(null);
  }

  // verificacion de correo del usuario propietario de la cuenta
  ConfirmEmail(token: string) {
    const headers = new HttpHeaders({
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    });
    return this.http.get<BaseResponse>(
      `${environment.apiUrl}api/Account/ConfirmEmail`,
      { headers }
    );
  }

  //ME LOGUEO EN LOS REPORTES
  public sign_inReport(
    username: string,
    password: string
  ): Observable<any> {
    const body = new HttpParams()
      .set('grant_type', 'password')
      .set('UserName', username)
      .set('Password', password)
      .set("is_login", "true");

    const headers = new HttpHeaders({
      'Content-Type': 'application/x-www-form-urlencoded',
    });

    return this.http
      .post<any>(`${environment.apiUrl}token`, body.toString(), {
        headers,
      })
      .pipe(
        map((user) => {
          if (user && user.access_token) {
            this.storage.setStorage('UserReport', user);
            //this.currentUserSubject.next(user);
          }
          return user;
        })
      );
  }

}
