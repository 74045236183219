export const environment = {
  production: false,
  url: '',
  apiUrl: 'https://medismartposapitest.clavisco.com/API/',
  env: 'Pruebas',
  urlReportManager: 'https://rptmngapiv2.clavisco.com/',
  companyKey: '39',
  appKey: '53',
  LogRocketId: ''
};
