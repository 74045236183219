import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { PagesComponent } from './pages/pages.component';
import { AuthInterceptor } from './theme/utils/auth.interceptor';
import { LoaderInterceptor } from './theme/utils/loader.interceptor';
import { ErrorHandlerInterceptor } from './theme/utils/error-handler.interceptor';
import { SharedModule } from './shared/shared.module';
import { SidenavMenuComponent } from './shared/sidenav-menu/sidenav-menu.component';

import { NotFoundComponent } from './../app/pages/not-found/not-found.component';
import { AppSettings } from './app.settings';
import { SpinnerComponent } from './pages/spinner/spinner.component';


@NgModule({
  declarations: [
    AppComponent,
    PagesComponent,
    SidenavMenuComponent,
    NotFoundComponent,
    SpinnerComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    HttpClientModule,
    SharedModule,
  ],
  providers: [
    AppSettings,
    {provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true},
    {provide: HTTP_INTERCEPTORS, useClass: LoaderInterceptor, multi: true},
    {provide: HTTP_INTERCEPTORS, useClass: ErrorHandlerInterceptor, multi: true}
  ],
  exports: [

  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
