import {AfterViewInit, ChangeDetectorRef, Component, OnInit} from '@angular/core';
import { Router } from '@angular/router';
import { AuthenticationService } from 'src/app/core/services/authentication.service';
import { SectionService } from 'src/app/core/services/section.service';

@Component({
  selector: 'app-not-found',
  templateUrl: './not-found.component.html',
  styleUrls: ['./not-found.component.scss']
})
export class NotFoundComponent implements OnInit, AfterViewInit {

  currentUser: any; // datos de sesion
  constructor(
    public router: Router,
    private authenticationService: AuthenticationService,
    private sectionService: SectionService,
    private cd: ChangeDetectorRef
  ) {
    this.authenticationService.currentUser.subscribe(next => {
      this.currentUser = next;
    });
  }

  ngOnInit(): void {
  }
  ngAfterViewInit(): void {
    this.cd.detectChanges();
  }
  //METODO PARA REDIRIGIR AL HOME SI ESTA LOGUEADO Y AL SIGNIN SINO
  public returnPage(): void {
    if (this.currentUser) {
      this.router.navigate(['/']);
      this.sectionService.nameSection = 'Inicio';
    } else {
      this.router.navigate(['/sign-in']);
    }
  }
}
