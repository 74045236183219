import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthenticateGuard } from './core/guards/authenticate.guard';
import { VerifyPermGuard } from './core/guards/verify-perm.guard';
import { NotFoundComponent } from './pages/not-found/not-found.component';
import { PagesComponent } from './pages/pages.component';
import {VerifyUserTokenGuard} from "./core/guards/verify-user-token.guard";

const routes: Routes = [
  {
    path: '',
    component: PagesComponent,
    children: [
      { path: '', loadChildren: () => import('./pages/home/home.module').then(m => m.HomeModule), canActivate: [AuthenticateGuard,VerifyUserTokenGuard] },
      { path: 'sign-in', loadChildren: () => import('./pages/sign-in/sign-in.module').then(m => m.SignInModule) },
      { path: 'updatePassword/:email', loadChildren: () => import('./pages/update-password/update-password.module').then(m => m.UpdatePasswordModule) },
      { path: 'ARInvoicesCreate', loadChildren: () => import('./pages/invoices/invoices.module').then(m => m.InvoicesModule), canActivate: [AuthenticateGuard, VerifyPermGuard,VerifyUserTokenGuard] },//S_CreateARInv
      { path: 'Users', loadChildren: () => import('./pages/users/users.module').then(m => m.UsersModules), canActivate: [AuthenticateGuard, VerifyPermGuard,VerifyUserTokenGuard] },//S_UserAssigns
      { path: 'Companys', loadChildren: () => import('./pages/company/company.module').then(m => m.CompanyModule), canActivate: [AuthenticateGuard, VerifyPermGuard,VerifyUserTokenGuard] }, //S_Companies
      { path: 'verificationEmail/:code', loadChildren: () => import('./pages/verification-email/verification-email.module').then(m => m.VerificationEmailModule) },
      { path: 'CurrentPayment', loadChildren: () => import('./pages/receive-payment/receive-payment.module').then(m => m.ReceivePaymentModule), canActivate: [AuthenticateGuard, VerifyPermGuard,VerifyUserTokenGuard] },//S_CurrentPayment
      { path: 'ClosingReports', loadChildren: () => import('./pages/closing-reports/closing-reports.module').then(m => m.ClosingReportsModule), canActivate: [AuthenticateGuard, VerifyPermGuard,VerifyUserTokenGuard] },
      { path: 'CancelPayment', loadChildren: () => import('./pages/cancel-payment/cancel-payment.module').then(m => m.CancelPaymentModule), canActivate: [AuthenticateGuard ,VerifyPermGuard,VerifyUserTokenGuard] },// S_CancelPayment
      { path: 'BACTerminals', loadChildren: () => import('./pages/terminals/terminals.module').then(m => m.TerminalsModule), canActivate: [AuthenticateGuard, VerifyPermGuard,VerifyUserTokenGuard] },//S_BACTerminals
      { path: 'SAPConnections', loadChildren: () => import('./pages/conexiones/conexiones.module').then(m => m.ConexionesModule), canActivate: [AuthenticateGuard, VerifyPermGuard,VerifyUserTokenGuard] },//S_SAPConnections
      { path: 'TransactionLogs', loadChildren: () => import('./pages/transaction-logs/transaction-log.module').then(m => m.TransactionLogModule), canActivate: [AuthenticateGuard, VerifyPermGuard,VerifyUserTokenGuard] },//M_LogTran
      { path: 'Reports', loadChildren: () => import('./pages/report/report.module').then(m => m.ReportModule), canActivate: [AuthenticateGuard, VerifyPermGuard,VerifyUserTokenGuard] },//M_Report
      { path: 'ConfigAutoSale', loadChildren: () => import('./pages/confi-auto-sale/confi-auto-sale.module').then(m => m.ConfigAutoSaleModule), canActivate: [AuthenticateGuard, VerifyPermGuard,VerifyUserTokenGuard] },//M_ConfigSale
      { path: 'BacError', loadChildren: () => import('./pages/bac-error/bac-error.module').then(m => m.BacErrorModule), canActivate: [AuthenticateGuard, VerifyPermGuard,VerifyUserTokenGuard] }//M_BACErrors
    ]
  },
  {
    path: '**', component: NotFoundComponent
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: true })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
