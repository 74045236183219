import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree} from '@angular/router';
import {concatMap, map, Observable, of} from 'rxjs';
import {DataStorageService} from "../services/data-storage.service";
import {AuthenticationService} from "../services/authentication.service";
import {MessageType} from "../enum/message";
import {GlobalFunctionsService} from "../services/global-functions.service";
import {D} from "@angular/cdk/keycodes";

@Injectable({
  providedIn: 'root'
})
export class VerifyUserTokenGuard implements CanActivate {
  constructor(
    private router: Router,
    private storage: DataStorageService,
    private authService:AuthenticationService,
    private gblSrv:GlobalFunctionsService,
  ) {
  }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {

    const userToken = JSON.parse(this.storage.getStorage('currentUser'));

    let existToken: boolean = !!userToken;
    let dateToken:string= new Date(userToken.ExpireTime).toISOString().split('T')[0];
    let dateNow:string= new Date().toISOString().split('T')[0];
    let isValidToken: boolean = !!userToken && (dateToken !== dateNow )&& !!userToken.access_token;
    let observable$: Observable<boolean> = of(isValidToken).pipe(
      concatMap(_validToken => {
        if (!_validToken && existToken) {
          this.gblSrv.displayMessage(`Su sesión ha caducado. Vuelva a iniciar sesión por favor.`, 'x', MessageType.information, 'top', 5000);
          this.authService.logout();
          return of(false);
        } else {
          if (!_validToken) {
            this.gblSrv.displayMessage(`Su sesión ha caducado. Vuelva a iniciar sesión por favor.`, 'x', MessageType.information, 'top', 5000);
            this.authService.logout();
          }
          return of(_validToken);
        }
      })
    );

    return observable$;

  }
}

