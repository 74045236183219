import {AfterViewInit, ChangeDetectorRef, Component, Input, OnInit} from '@angular/core';
import { Router } from '@angular/router';
import { AuthenticationService } from 'src/app/core/services/authentication.service';
import { SectionService } from 'src/app/core/services/section.service';
import { SidenavMenu } from './sidenav-menu-model';


@Component({
  selector: 'app-sidenav-menu',
  templateUrl: './sidenav-menu.component.html',
  styleUrls: ['./sidenav-menu.component.scss']
})
export class SidenavMenuComponent implements OnInit, AfterViewInit {

  // @Input('menuItems') menuItems: any;
  // @Input('menuParentId') menuParentId: any;
  // parentMenu!: Array<any>;

  // constructor(private sidenavMenuService: SidenavMenuService) { }

  // ngOnInit() {
  //   this.parentMenu = this.menuItems.filter((item: any) => item.parentId == this.menuParentId);
  // }

  // onClick(menuId: any) {
  //   this.sidenavMenuService.toggleMenuItem(menuId);
  //   this.sidenavMenuService.closeOtherSubMenus(this.menuItems, menuId);
  // }


  @Input('menuItems') menuItems!: SidenavMenu[];
  @Input('menuParentId') menuParentId: any;

  constructor(
    public authService: AuthenticationService,
    private router: Router,
    private sectionService :SectionService,
    private cd: ChangeDetectorRef
  ) { }

  ngOnInit(): void {
    this.onLoad();
  }

  onLoad() {

  }
  ngAfterViewInit(): void {
    this.cd.detectChanges();
  }
  onClick(item: SidenavMenu) {

    this.toggleMenuItem(item.id);
    this.closeOtherSubMenus(this.menuItems, item.id);
    this.sectionService.nameSection = item.name;
    // HACEMOS LOGOUT
    if (item.name === 'Salir') {
      this.authService.logout();
    }
    if ((item.parentId !== 0) || (item.parentId === 0 && item.submenu && item.submenu.length === 0)) {
      this.router.navigate([`/${item.url}`]);
    }
  }

  toggleMenuItem(menuId: number) {
    let menuItem = document.getElementById('menu-item-' + menuId);
    let subMenu = document.getElementById('sub-menu-' + menuId);
    if (subMenu) {
      if (subMenu.classList.contains('show')) {
        subMenu.classList.remove('show');
        menuItem?.classList.remove('expanded');
      }
      else {
        subMenu.classList.add('show');
        menuItem?.classList.add('expanded');
      }
    }
  }


  closeOtherSubMenus(menu: Array<SidenavMenu>, menuId: number) {
    let currentMenuItem = menu.filter(item => item.id == menuId)[0];
    menu.forEach(item => {
      if ((item.id != menuId && item.parentId == currentMenuItem.parentId) || (currentMenuItem.parentId == 0 && item.id != menuId)) {
        let subMenu = document.getElementById('sub-menu-' + item.id);
        let menuItem = document.getElementById('menu-item-' + item.id);
        if (subMenu) {
          if (subMenu.classList.contains('show')) {
            subMenu.classList.remove('show');
            menuItem?.classList.remove('expanded');
          }
        }
      }
    });
  }


}
