<div class="example-container">
    <mat-sidenav-container class="example-sidenav-container">
        <mat-sidenav #snav [mode]="'side'" *ngIf="currentUser" class="sideItems blue-background-color" color='primary'>
            <div class="SidebarLogo mat-elevation-4">
                <img src="assets/img/logo/logo-medismart.svg">
            </div>
            <div class="SessionUser">
                {{currentUser.UserEmail}}
            </div>
            <mat-divider class="mb-2 mt-2"></mat-divider>
            <app-sidenav-menu *ngIf="sidenavMenuItems && sidenavMenuItems.length > 0" [menuItems]="sidenavMenuItems"
                [menuParentId]="0">
            </app-sidenav-menu>
        </mat-sidenav>
        <mat-sidenav-content class="mat-sidenav-color">
            <mat-toolbar *ngIf="currentUser" class="toolbar-color mat-elevation-z4">
                <button mat-icon-button (click)="snav.toggle()">
                    <mat-icon>menu</mat-icon>
                </button>
                <h1 class="example-app-name">{{sectionService.nameSection}}</h1>
            </mat-toolbar> 
            <div class="flex-wrapper">
                <router-outlet></router-outlet>   
            </div>       
        </mat-sidenav-content>
    </mat-sidenav-container>
    
</div>