import {AfterViewInit, ChangeDetectorRef, Component, Inject, OnInit} from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { forkJoin } from 'rxjs';
import { MessageType } from 'src/app/core/enum/message';
import { BusinessPartnerModel } from 'src/app/core/interfaces/BusinessPartnerModel';
import { BusinessPartnerService } from 'src/app/core/services/business-partner.service';
import { GlobalFunctionsService } from 'src/app/core/services/global-functions.service';

@Component({
  selector: 'app-paginator-bp',
  templateUrl: './paginator-bp.component.html',
  styleUrls: ['./paginator-bp.component.scss']
})
export class PaginatorBpComponent implements OnInit,AfterViewInit {

  /*LISTAS*/
  bp!: BusinessPartnerModel;

  /*VARIABLES*/
  take: number = 100;
  page: number = 1;
  quantityBP: number = 0;
  filter: string = '';

  /*TABLA*/
  displayedColumns: string[] = ['Sel', 'AddID', 'CardCode', 'CardName'];
  dataSource = new MatTableDataSource<BusinessPartnerModel>();

  constructor(
    public dialogRef: MatDialogRef<PaginatorBpComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private bpSvc: BusinessPartnerService,
    private gblSrv: GlobalFunctionsService,
    private cd: ChangeDetectorRef
  ) { }

  ngOnInit(): void {

  }

  ngAfterViewInit(): void {
    this.cd.detectChanges();
  }
  //METODO PARA TRAER LOS DATOS
  private getBP(data: string, skip: number, take: number): void {
    let list$ = this.bpSvc.getBPPaginator(skip, take, data.toUpperCase());
    let count$ = this.bpSvc.getQuantityBp(data.toUpperCase());

    forkJoin({
      BPPaginatorResponse: list$,
      CountBPResponse: count$
    }).subscribe((results) => {
      let sms: string = '';

      if (results.BPPaginatorResponse) {
        if (results.BPPaginatorResponse.result) {
          this.dataSource.data = results.BPPaginatorResponse.BP;
        } else {
          this.dataSource.data = [];
          sms = `${results.BPPaginatorResponse.errorInfo.Message}`;
        }
      }

      if (results.CountBPResponse) {
        if (results.CountBPResponse.result) {
          this.quantityBP = results.CountBPResponse.QuantityBP;
        } else {
          this.quantityBP = 0;
          sms = `${sms} ${results.CountBPResponse.errorInfo.Message}`;
        }
      }

      if (sms.length > 0) {
        this.gblSrv.displayMessage(`${sms}!`, 'x', MessageType.warning, 'top', 5000);
      }
    });
  }

  // METODO PARA CERRAR EL FORMULARIO
  public onNoClick(data: BusinessPartnerModel): void {
    this.dialogRef.close(data);
  }

  //EVENTO DEL PAGINADOR
  public event(event: any): void {
    const skip = event.pageIndex * event.pageSize;
    const take = event.pageSize;
    this.getBP(this.filter, skip, take);
  }

  //METODO BUSCAR
  public search(event: any): void {

    let data: string = event.target.value;

    if (data.length >= 4) {
      this.filter = data;

      this.getBP(data, 0, 100);
    }
  }

  //METODO CUANDO SELECCIONA
  public select(data: BusinessPartnerModel): void {
    this.bp = data;
    this.onNoClick(this.bp);
  }

  //METODO PARA CANCELAR
  public cancel(): void {
    this.onNoClick(this.bp);
  }

}
