import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { DataStorageService } from 'src/app/core/services/data-storage.service';
import { GlobalFunctionsService } from 'src/app/core/services/global-functions.service';
import { MessageType } from 'src/app/core/enum/message';
import { AuthenticationService } from 'src/app/core/services/authentication.service';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {

  constructor(
    private dataStorageService: DataStorageService,
    private gblSrv:GlobalFunctionsService,
    private authService:AuthenticationService
    ) { }

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    // si se pide token se retorna el request de una vez
    if (request.url.includes('token')) return next.handle(request);

    const res = this.dataStorageService.getToken(request.url);

    let req = request;

    if (res) {
      //Obtenemos el token del sessioStorage
      const TOKEN = JSON.parse(res);

      //Validamos si el token existe
      if (TOKEN) {
        var expires = new Date(TOKEN.ExpireTime);
        var today = new Date();
        const resultDate:number = expires.getTime() - 10 * 60 * 1000;
        const newDate:Date = new Date(resultDate);
        if (today <= newDate) { // posee token y el mismo aun no expira, es valido
          //Clonamos el token y lo mandamos en la cabecera de todas las peticiones HTTP
          req = request.clone({
            setHeaders: {
              'Content-Type': 'application/json',
              //Autorizaciòn de tipo Bearer + token
              //El tipo de autorizaciòn depende del back
              authorization: `Bearer ${TOKEN.access_token}`
            }
          });
        } else {
          // token vencio
          this.gblSrv.displayMessage(`El token ha expirado, por favor vuelva a loguearse!`, 'x', MessageType.information, 'top', 5000);
          this.authService.logout();
          return throwError(() => new Error('invalid token'));
        }
      }
    }
    return next.handle(req);
  }
}
