<div class="sidebar ">
    <div *ngFor="let menu of menuItems" class="menu-item">
        <!-- <a *ngIf="menu.url && (menu.submenu && menu.submenu.length === 0) && menu.parentId === 0" mat-button fxLayout="row"
        fxLayoutAlign="start center" [
        routerLink]="[menu.url]" routerLinkActive="active-link" [routerLinkActiveOptions]="{exact:true}" 
        (click)="onClick(menu.id,menu.url)" [id]="'menu-item-'+menu.id">
        <span class="menu-title">{{menu.name}}</span>
    </a> -->
        <!-- LOS QUE NO TIENEN SUBMENU -->
        <a *ngIf="menu.url && (menu.submenu && menu.submenu.length === 0) && menu.parentId === 0 && menu.havePerm" mat-button
            fxLayout="row" fxLayoutAlign="start center" (click)="onClick(menu)" [id]="'menu-item-'+menu.id" class="text-left">
            <mat-icon class="mr-1">{{menu.iconClass}}</mat-icon>
            <span class="menu-title">{{menu.name}}</span>
        </a>
        <!-- LOGOUT -->
        <a *ngIf="!menu.url && (menu.submenu && menu.submenu.length === 0) && menu.parentId === 0 && menu.havePerm" mat-button
            fxLayout="row" fxLayoutAlign="start center" (click)="onClick(menu)" [id]="'menu-item-'+menu.id" class="text-left">
            <mat-icon class="mr-1">{{menu.iconClass}}</mat-icon>
            <span class="menu-title">{{menu.name}}</span>
        </a>
        <!-- CON SUBMENU -->
        <a *ngIf="(menu.submenu && menu.submenu.length > 0) && menu.parentId === 0 && menu.havePerm" mat-button fxLayout="row"
            fxLayoutAlign="start center" (click)="onClick(menu)" [id]="'menu-item-'+menu.id"  class="text-left">
            <mat-icon class="mr-1">{{menu.iconClass}}</mat-icon>
            <span class="menu-title">{{menu.name}}</span>
            <mat-icon class="menu-expand-icon transition-2">expand_more</mat-icon>
        </a>
        <div *ngIf="(menu.submenu && menu.submenu.length > 0)" class="sub-menu" [id]="'sub-menu-'+menu.id">
            <app-sidenav-menu [menuItems]="menu.submenu" [menuParentId]="menu.id"></app-sidenav-menu>
        </div>
        <!-- SUBMENUS -->
        <a *ngIf="menu.url && (menu.submenu && menu.submenu.length === 0) && menu.parentId > 0 && menu.havePerm" mat-button
            fxLayout="row" fxLayoutAlign="start center" (click)="onClick(menu)" [id]="'menu-item-'+menu.id"  class="text-left">
            <mat-icon class="mr-1">{{menu.iconClass}}</mat-icon>
            <span class="menu-title">{{menu.name}}</span>
        </a>
    </div>
</div>