import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { SidenavMenu } from 'src/app/shared/sidenav-menu/sidenav-menu-model';

@Injectable({
  providedIn: 'root'
})
export class InternalRequestService {

  public url = 'assets/data/';

  constructor(
    public http: HttpClient
  ) { }

  public getMenu(): Observable<SidenavMenu[]> {
    return this.http.get<SidenavMenu[]>(this.url + 'menu.json');
  }
}
